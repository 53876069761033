import { Router } from 'vue-router'
import { Store } from 'pinia'

import {
  ApplicationInsights,
  IConfig,
  IConfiguration,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web'
import { User } from '@sortera/firebase-auth'

import { analyticsAreEnabled } from './helpers'

// GLOBAL PROPERTIES
export const GLOBAL_PROP_ROLE_NAME = 'DriverView' as const
export const GLOBAL_PROP_USER_EMAIL = 'UserEmail' as const
export const GLOBAL_PROP_REGION = 'Region' as const
export const GLOBAL_PROP_PAGE_NAME = 'PageName' as const

const GOOGLE_BASE_DOMAIN = 'googleapis.com' as const

let appInsights: ApplicationInsights | undefined

export function useAppInsights(): ApplicationInsights {
  if (appInsights === undefined) {
    throw new Error('Application Insights must be initialized before use')
  }
  return appInsights
}

export function initAnalytics(
  router: Router,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any --- can't be bothered with typing the store right now, but it should be done eventually to avoid the getter being of type any
  store: Store<any, any>,
  getUser: () => User | null | undefined
): void {
  if (appInsights !== undefined || !analyticsAreEnabled()) return
  const config: IConfig & IConfiguration = {
    connectionString: import.meta.env.VITE_APPLICATIONINSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: false,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
  }
  try {
    appInsights = new ApplicationInsights({ config })
    appInsights.addTelemetryInitializer(addRoleNameToTelemetryItem)
    appInsights.addTelemetryInitializer(getAddUserToTelemetryItem(getUser))
    appInsights.addTelemetryInitializer(getAddRegionToTelemetryItem(store))
    appInsights.addTelemetryInitializer(getAddPageViewToTelemetryItem(router))
    appInsights.addTelemetryInitializer(googleDependencyTelemetryFilter)
    appInsights.loadAppInsights()
  } catch (err) {
    console.error(err)
  }
}

export function isAnalyticsInitialized(): boolean {
  return appInsights !== undefined
}

function addRoleNameToTelemetryItem(item: ITelemetryItem): boolean {
  if (!Array.isArray(item.tags)) {
    item.tags = []
  }
  item.tags = [{ 'ai.cloud.role': GLOBAL_PROP_ROLE_NAME }]
  return true
}

function getAddUserToTelemetryItem(
  getUser: () => User | null | undefined
): (item: ITelemetryItem) => boolean {
  return (item: ITelemetryItem): boolean => {
    try {
      const user = getUser()

      const appInsights = useAppInsights()
      if (user) {
        if (user?.accountId) {
          appInsights.setAuthenticatedUserContext(user.accountId)
        }

        item.data = {
          ...(item.data ?? {}),
          [GLOBAL_PROP_USER_EMAIL]: user.email,
        }
      }
    } catch (err) {
      console.error(err)
    }
    return true
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any --- can't be bothered with typing the store right now, but it should be done eventually to avoid the getter being of type any
function getAddRegionToTelemetryItem(store: Store<any, any>): (item: ITelemetryItem) => boolean {
  return (item: ITelemetryItem) => {
    const code = store.state.value.app.currentRegion?.code
    item.data = {
      ...(item.data ?? {}),
      [GLOBAL_PROP_REGION]: code,
    }
    return true
  }
}

function getAddPageViewToTelemetryItem(router: Router): (item: ITelemetryItem) => boolean {
  return (item: ITelemetryItem) => {
    item.data = {
      ...(item.data ?? {}),
      [GLOBAL_PROP_PAGE_NAME]: router.currentRoute.value.name ?? router.currentRoute.value.fullPath,
    }
    return true
  }
}

function googleDependencyTelemetryFilter(item: ITelemetryItem): boolean {
  function isDependency(item: ITelemetryItem) {
    return item.baseType === 'RemoteDependencyData'
  }

  function isCallingGoogle(item: ITelemetryItem) {
    return (
      item.baseData?.name?.includes(GOOGLE_BASE_DOMAIN) ||
      item.baseData?.target?.includes(GOOGLE_BASE_DOMAIN)
    )
  }

  function wasSuccessful(item: ITelemetryItem) {
    return item.baseData?.responseCode >= 200 && item.baseData?.responseCode < 400
  }

  if (!isDependency(item) || !item.baseData) {
    return true
  }

  if (!isCallingGoogle(item)) {
    return true
  }

  if (!wasSuccessful(item)) {
    return true
  }

  return false
}
