import { App } from 'vue'
import { Router } from 'vue-router'
import { Store } from 'pinia'

import { User } from '@sortera/firebase-auth'

import { analyticsAreEnabled, initAnalytics, trackError, trackPageView } from '@/utils/analytics'

export function usePluginAnalytics(
  app: App,
  router: Router,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any --- can't be bothered with typing the store right now, but it should be done eventually to avoid the getter being of type any
  store: Store<any, any>,
  getUser: () => User | null | undefined
) {
  if (!analyticsAreEnabled()) {
    return
  }

  initAnalytics(router, store, getUser)

  const prevErrorHandler = app.config.errorHandler
  app.config.errorHandler = (err, instance, info) => {
    if (err instanceof Error) {
      trackError(err)
    }
    // ensure to not override any other error handler:
    prevErrorHandler?.(err, instance, info)
    throw err
  }

  router.beforeEach((to, from, next) => {
    trackPageView(to)
    next()
  })

  router.onError(err => {
    trackError(err)
  })
}
