import { doc, getDoc } from 'firebase/firestore'
import { useFirestore } from 'vuefire'

import { DateTime } from 'luxon'

import i18n from '@/i18n'
import MainLayout from '@/layouts/main-layout.vue'
import { useAppStore } from '@/stores/app'
import { ensureUpToDateEntry } from '@/utils/ensure-entry'

import { ROUTES } from './constants'

const { t } = i18n.global

const delay = 1000 * 60 * 5
window.intervalId = null

export default [
  // NO LAYOUT

  // These auth helper pages will never be reached via the router.
  // Adding these only here to indicate that the URLs are taken.
  // We are rewriting to the statically hosted .html files,
  // that can be found under the same path in the public folder.
  // Locally, the dev server is taking care of the rewrite.
  // When deploying to a web server, a reverse proxy (independent or built in web server)
  // should handle directing incoming traffic to the proper files if they match these path
  { path: '/__/auth/handler' },
  { path: '/__/auth/iframe' },

  {
    // If 3rd party cookies are blocked, we can't use the idToken from the firebase auth flow.
    // The user need to login one more time, directly with MSAL.
    // Therefore we have this separate redirect page.
    path: '/autentisering/logga-in',
    name: ROUTES.AUTH_MSAL,
    component: () => import('@/views/auth-msal-redirect.vue'),
    meta: { authRequired: false, title: t('auth.signing-in') },
  },

  // WRAPPED IN MAIN LAYOUT

  {
    path: '/',
    component: MainLayout,
    // all children wrapped in layout
    children: [
      {
        path: '/',
        name: ROUTES.HOME,
        meta: { authRequired: true, title: t('routes.home') },
        component: () => import('@/views/home-view.vue'),
      },
      {
        path: '/migrate',
        name: 'migrate',
        meta: { authRequired: true },
        component: () => import('@/views/migrate-view.vue'),
      },
      {
        path: '/settings',
        name: ROUTES.SETTINGS,
        meta: { authRequired: true, title: t('routes.settings') },
        component: () => import('@/views/settings-view.vue'),
      },
      {
        path: '/flags',
        name: ROUTES.FLAGS,
        meta: { authRequired: true, title: t('routes.flags') },
        component: () => import('@/views/flags-view.vue'),
      },
      {
        path: '/admin',
        name: ROUTES.ADMIN,
        meta: { authRequired: true, title: t('routes.admin') },
        component: () => import('@/views/admin-view.vue'),
      },
      {
        path: '/assign/:region',
        name: ROUTES.ASSIGN,
        meta: {
          authRequired: true,
          title: t('routes.assign'),
          beforeResolve(to, _, next) {
            const store = useAppStore()
            const region = store.regions.find(region => region.code === to.params.region)

            store.$patch({
              currentResource: to.params.resource,
              currentRegion: region,
            })
            next()
          },
        },
        component: () => import('@/views/assign-view.vue'),
      },
      {
        path: '/region/:region/vehicle/:resource',
        meta: {
          authRequired: true,
          async beforeResolve(to, _, next) {
            const store = useAppStore()
            const region = store.regions.find(region => region.code === to.params.region)

            store.$patch({
              currentResource: to.params.resource,
              currentRegion: region,
            })

            // since we are not actively using the order list, we don't need to update it
            if (
              to.name === ROUTES.ORDER_LIST_BY_DATE ||
              to.name === ROUTES.PARKED_LIST ||
              to.name === ROUTES.PARKED_CONTAINER
            ) {
              if (window.intervalId) clearInterval(window.intervalId)
              next()
              return
            }

            if (store.orders.length === 0) await store.getOrders()

            if (!window.intervalId)
              window.intervalId = setInterval(() => {
                store.setDate(DateTime.local().toISODate())
                store.getOrders()
              }, delay)

            next()
          },
        },
        redirect: { name: ROUTES.ORDER_LIST },
        children: [
          {
            path: 'order/:id',
            name: ROUTES.ORDER,
            meta: {
              authRequired: true,
              title: t('routes.order'),
              async beforeResolve(to, _, next) {
                const store = useAppStore()
                const order = store.orders.find(o => {
                  const oId = o.subOrderCode || String(o.id)
                  return oId === String(to.params.id)
                })

                if (!order) {
                  next({
                    name: ROUTES.ORDER_LIST,
                    params: {
                      region: to.params.region,
                      resource: to.params.resource,
                    },
                  })
                } else {
                  const processedOrder = await ensureUpToDateEntry(order, store)

                  store.$patch({ selectedOrder: processedOrder })
                  next()
                }
              },
            },
            component: () => import('@/views/order-view.vue'),
          },
          {
            path: 'parked',
            name: ROUTES.PARKED_LIST,
            meta: { authRequired: true, title: t('routes.parked') },
            component: () => import('@/views/parked-list.vue'),
          },
          {
            path: 'parked/:docId',
            name: ROUTES.PARKED_CONTAINER,
            meta: {
              authRequired: true,
              title: t('routes.parked-container'),
              async beforeResolve(to, _, next) {
                const db = useFirestore()
                const containerRef = doc(db, 'parked', to.params.docId)

                const docSnap = await getDoc(containerRef)
                if (!docSnap.exists())
                  next({
                    name: ROUTES.PARKED_LIST,
                    params: {
                      region: to.params.region,
                      resource: to.params.resource,
                    },
                  })

                next()
              },
            },
            component: () => import('@/views/parked-container.vue'),
          },
          {
            path: ':date',
            name: ROUTES.ORDER_LIST_BY_DATE,
            meta: {
              authRequired: true,
              async beforeResolve(to, _, next) {
                const store = useAppStore()
                if (to.params.date === 'overview') {
                  next({
                    name: ROUTES.ORDER_LIST,
                    params: {
                      region: to.params.region,
                      resource: to.params.resource,
                    },
                  })
                  return
                }
                store.$patch({
                  currentDate: to.params.date,
                  followToday: false,
                })
                await store.getOrders()
                next()
              },
            },
            component: () => import('@/views/order-list.vue'),
          },
          {
            path: '',
            name: ROUTES.ORDER_LIST,
            meta: {
              authRequired: true,
              title: t('routes.order-list'),
              async beforeResolve(_, __, next) {
                const store = useAppStore()
                store.$patch({
                  followToday: true,
                  currentDate: DateTime.local().toISODate(),
                })
                store.setDate(DateTime.local().toISODate())

                if (store.orders.length === 0) await store.getOrders()
                else store.getOrders()
                next()
              },
            },
            component: () => import('@/views/order-list.vue'),
          },
        ],
      },
    ],
  },

  // OUTSIDE MAIN LAYOUT

  {
    path: '/:pathMatch(.*)*',
    meta: { title: t('routes.404') },
    component: () => import('@/views/error-page.vue'),
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
]
