import { collection, doc, getDoc, setDoc } from 'firebase/firestore'
import { useDocument, useFirestore } from 'vuefire'
import { acceptHMRUpdate, defineStore } from 'pinia'

import { DateTime } from 'luxon'
import NProgress from 'nprogress/nprogress'

import { useAuth } from '@/composables/auth'
import axios from '@/plugins/axios.api'
import { settingsRef } from '@/plugins/firebase-db'
import {
  AKERISACKEN,
  BIG_BAG,
  SORTERA_GOTEBORG,
  SORTERA_MALMO,
  SORTERA_OREBRO,
  SORTERA_STOCKHOLM,
} from '@/utils/company.constants'
import { bagDelivery, crane, faBags, faContainer, receptacle } from '@/utils/identify-order'
import {
  companyToRegion,
  GOTEBORG,
  MALMO,
  OREBRO,
  regionToCompany,
  STOCKHOLM,
} from '@/utils/regions'

const db = useFirestore()

/**
 * Returns a random uppercase letter from A to Z.
 * @returns {string} The random letter.
 */
function randomLetter() {
  return String.fromCharCode(65 + Math.floor(Math.random() * 26))
}

export const modals = [
  {
    prop: 'bom',
    title: 'order.toolbar.bom',
    icon: 'shield-exclamation',
    condition: () => true,
  },
  {
    prop: 'beforeAfter',
    title: 'order.toolbar.beforeAfter.title',
    icon: 'copy',
    condition: () => true,
  },
  {
    prop: 'extra',
    title: 'order.toolbar.extra',
    icon: 'hourglass-clock',
    condition: () => true,
  },
  {
    prop: 'receipt',
    title: 'order.toolbar.receipt',
    icon: 'receipt',
    condition: () => true,
  },
  {
    prop: 'sig',
    title: 'order.toolbar.sig',
    icon: 'signature',
    condition: order => bagDelivery(order),
  },
  {
    prop: 'mark',
    title: 'order.toolbar.mark',
    icon: 'box-check',
    allowUnmarked: true,
    condition: order => crane(order) || faBags(order) || faContainer(order),
  },
  {
    prop: 'mark',
    title: 'order.toolbar.mark',
    icon: 'box-check',
    condition: order => receptacle(order),
  },
  {
    prop: 'etc',
    title: 'order.toolbar.etc',
    icon: 'note',
    condition: () => true,
  },
]

export const useAppStore = defineStore('app', {
  state: () => ({
    regions: [
      {
        id: 1,
        code: STOCKHOLM,
        name: 'Stockholm',
        companies: [SORTERA_STOCKHOLM, BIG_BAG, AKERISACKEN],
      },
      { id: 2, code: GOTEBORG, name: 'Göteborg', companies: [SORTERA_GOTEBORG] },
      { id: 3, code: MALMO, name: 'Malmö', companies: [SORTERA_MALMO] },
      { id: 4, code: OREBRO, name: 'Örebro', companies: [SORTERA_OREBRO] },
    ],
    currentRegion: { id: 1, code: STOCKHOLM, name: 'Stockholm' },
    currentResource: null,
    currentDate: DateTime.local().toISODate(),
    followToday: true,
    orders: [],
    ordersVolume: 0,
    selectedOrder: null, // since we have the entire order in a list, we store the current order here

    reservedLetter: randomLetter(),

    autologin: useDocument(doc(settingsRef, 'autologin')),
    driverAdmin: useDocument(doc(settingsRef, 'driverAdmin')),
    enableSelect: useDocument(doc(settingsRef, 'enableSelect')),
    edge: useDocument(doc(settingsRef, 'edge')),
    demo: useDocument(doc(settingsRef, 'demo')),
    version: useDocument(doc(settingsRef, 'version')),
    faRollout: useDocument(doc(settingsRef, 'faRollout')),
  }),

  getters: {
    currentRegionCode() {
      if (!this.currentRegion) return STOCKHOLM
      else return this.currentRegion.code
    },
    /** TODO: rename to `email` */
    username() {
      return useAuth().account.value?.email.toLowerCase()
    },
    currentUserSpaced() {
      return useAuth().account.value?.name
    },
    IsAdmin() {
      if (this.driverAdmin && Object.keys(this.driverAdmin).length > 0)
        return useAuth().account.value && this.username in this.driverAdmin
      return false
    },
    IsEdge() {
      if (this.edge && Object.keys(this.edge).length > 0)
        return useAuth().account.value && this.username in this.edge
      return false
    },
    IsDemo() {
      if (this.demo && Object.keys(this.demo).length > 0)
        return useAuth().account.value && this.username in this.demo
      return false
    },
    /**
     * Returns the driver mark for the bag pickup. Admins can change the suggested mark.
     * @returns {string} The driver mark.
     */
    driverMark() {
      let mark = this.reservedLetter
      if (!this.autologin) return mark

      const autologin = this.autologin[this.username]
      if (autologin) {
        // stockholm/311/W or malmo/M103
        const splits = autologin.split('/')
        if (splits.length > 2 && splits[2].length > 0) {
          mark = splits[2]
        }
      }

      return mark
    },
  },

  actions: {
    /**
     * Sets the current date of the app.
     * @param {String} date - The new date to set. ISO 8601 format.
     */
    setDate(date) {
      if (this.followToday) this.$patch({ currentDate: date })
    },
    /**
     * Retrieves active resources for a given region.
     * @param {string} regionCode - The code of the region to retrieve resources for.
     * @returns {Array<string>} An array of active resource codes.
     */
    async getResources(regionCode) {
      if (!this.currentRegion || this.currentRegion.code !== regionCode) {
        const region = this.regions.find(region => region.code === regionCode)
        this.$patch({ currentRegion: region })
      }

      const data = await fetchResources(this.currentRegionCode)
      const resources = data.filter(r => r.isActive).map(r => r.resourceCode)

      return resources
    },
    /**
     * Retrieves a list of orders from the server and sorts them by delivery sequence position.
     * If a selected order is set, it will be included in the returned list and marked as selected.
     * @async
     * @returns {Promise<Array>} A promise that resolves to an array of orders.
     */
    async getOrders() {
      if (useAuth().account.value) logUser(this.username)

      NProgress.start()
      const data = await fetchOrders(this.currentRegionCode, this.currentResource, this.currentDate)
      if (data.length === 0) {
        NProgress.done()
        return []
      }

      const orders = data[0].orders.sort((a, b) => a.deliverySequencePos - b.deliverySequencePos)

      const selectedOrder = this.selectedOrder
        ? orders.find(o => {
            const oId = o.subOrderCode || o.id
            const id = this.selectedOrder.subOrderCode || this.selectedOrder.id
            return oId === id
          })
        : null

      this.$patch({ orders, selectedOrder, ordersVolume: data[0].totalVolume })
      NProgress.done()
      return orders
    },
    isOrderInFaRollout(order) {
      const orderRegion = companyToRegion(order.companyCode)
      if (this.faRollout && Object.keys(this.faRollout).length > 0)
        return orderRegion in this.faRollout
      return false
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}

/**
 * Logs when a user uses the app.
 * @async
 * @param {string} currentUsername - The username of the current user.
 * @returns {Promise<void>}
 */
async function logUser(currentUsername) {
  // we want to log when a user uses the app, hence the name: pulse
  const now = DateTime.utc()
  const pulseRef = doc(collection(db, 'pulse'), `${currentUsername}:${now.toFormat('yyyy-MM')}`)

  const currentDay = now.day

  const docSnap = await getDoc(pulseRef)
  if (docSnap.exists()) {
    const payload = docSnap.data()
    if ('days' in payload) {
      if (currentDay in payload.days) payload.days[currentDay].lastSeen = now.toMillis()
      else
        payload.days[currentDay] = {
          day: currentDay,
          firstSeen: now.toMillis(),
          lastSeen: now.toMillis(),
        }
    } else payload.days = {}

    setDoc(pulseRef, payload, { merge: true })
  } else {
    const payload = {
      month: now.month,
      year: now.year,
      username: currentUsername,
      period: now.toFormat('yyyy-MM'),
      days: {},
    }

    payload.days[currentDay] = {
      day: currentDay,
      firstSeen: now.toMillis(),
      lastSeen: now.toMillis(),
    }

    setDoc(pulseRef, payload)
  }
}

/**
 * Fetches resources for a given region code.
 * @param {string} regionCode - The region code for which to fetch resources.
 * @returns {Promise<any>} - A promise that resolves to the fetched resources.
 */
export async function fetchResources(regionCode) {
  const companyCode = regionToCompany(regionCode)
  return (await axios.get(`/api/resources?onlyActive=false&companyCode=${companyCode}`)).data
}

/**
 * Fetches orders from the server.
 * @param {string} regionCode - The region code.
 * @param {string} resource - The resource code.
 * @param {number} timestamp - The timestamp.
 * @returns {Promise<any>} - A promise that resolves to the fetched data.
 */
export async function fetchOrders(regionCode, resource, timestamp) {
  const companyCode = regionToCompany(regionCode)
  return (
    await axios.get(
      `/api/resources?onlyActive=false&companyCode=${companyCode}&timestamp=${timestamp}&resourceCode=${encodeURIComponent(
        resource
      )}`
    )
  ).data
}
